<template>
  <div id="loadingScreen" :style="`background-color: ${color}`" v-if="!loaded">
    <div id="loading-circle" class="loading-circle">
      <svg
        width="200"
        height="200"
        viewBox="0 0 200 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M199 100C199 154.676 154.676 199 100 199C45.3238 199 1 154.676 1 100C1 45.3238 45.3238 1 100 1C154.676 1 199 45.3238 199 100Z"
            :stroke="colorRev"
            stroke-width="2"
            stroke-dasharray="628"
            :stroke-dashoffset="628 - loadedPercent * 6.28"
          />
        </g>
      </svg>
      <span id="percent" :style="`color: ${colorRev};`">{{
        loadedPercent.toFixed(0) > 100 ? 100 : loadedPercent.toFixed(0)
      }}</span>
    </div>
    <p
      v-html="
        lang === 0
          ? 'Prepare your finger<br> for frantic scrolling'
          : 'Приготовьтесь<br> погрузиться в 3D'
      "
      :style="`color: ${colorRev};`"
    />
  </div>
  <Babylon
    :debug="debug"
    :frame="frame"
    :loaded="loaded"
    :update="updatePercent"
  />
  <UI
    :frame="frame"
    :anime="animation"
    v-if="!debug"
    :posabs="posabs"
    :lang="lang"
  />
</template>

<script>
import Babylon from "../components/Babylon.vue";
import UI from "../components/UI";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// window.history.scrollRestoration = "manual";
export default {
  name: "App",
  data() {
    return {
      frame: 0,
      loadedPercent: 0,
      loaded: false,
      fake: 0,
      debug: false,
      animation: {
        weight: 490000,
        container: 35,
        motor: 0,
      },
      posabs: {
        ship: 10000,
        motor: 10000,
        building: 10000,
        train: 10000,
      },
      lang: 0,
    };
  },
  components: {
    Babylon,
    UI,
  },
  computed: {
    color() {
      return `rgba(${this.loadedPercent * 2.5}, ${this.loadedPercent * 2.5}, ${
        this.loadedPercent * 2.5
      }, 1)`;
    },
    colorRev() {
      return `rgba(${255 - this.loadedPercent * 2.5}, ${
        255 - this.loadedPercent * 2.5
      }, ${255 - this.loadedPercent * 2.5}, 1)`;
    },
  },
  methods: {
    updatePercent(percent) {
      this.loadedPercent = percent;
    },
    scroll() {
      this.posabs.building = document
        .getElementsByClassName("building")[0]
        .getBoundingClientRect().top;
      this.posabs.motor = document
        .getElementsByClassName("motor")[0]
        .getBoundingClientRect().top;
      this.posabs.train = document
        .getElementsByClassName("train")[0]
        .getBoundingClientRect().top;
      this.posabs.ship = document
        .getElementsByClassName("third-row")[0]
        .getBoundingClientRect().top;
    },
  },
  watch: {
    loadedPercent(newValue) {
      if (newValue === 100) {
        this.loaded = true;
      }
    },
  },
  mounted() {
    const self = this;
    // Initial setup
    const root = document.documentElement;
    root.style.setProperty("--height", window.screen.height + "px");
    let finalWidth =
      window.innerWidth > (window.innerHeight / 1080) * 1920
        ? (window.innerHeight / 1080) * 1920
        : window.innerWidth;
    root.style.setProperty("--vw", finalWidth / 100 + "px");
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);
    gsap.to(window, {
      duration: 0,
      scrollTo: 0,
    });
    ScrollTrigger.config({ ignoreMobileResize: true });
    // ScrollTrigger.config({
    //   autoRefreshEvents: "visibilitychange,DOMContentLoaded,load",
    // });
    if (!this.debug) {
      // eslint-disable-next-line no-unused-vars
      gsap.utils.toArray(".section").forEach((elem, index) => {
        const startStop = [
          { start: 0, end: 5 },
          { start: 5, end: 9.83 },
          { start: 9.83, end: 15 },
          { start: 15, end: 19.96 },
          { start: 19.96, end: 25 },
          { start: 25, end: 29.97 },
          { start: 29.97, end: 35 },
          { start: 35, end: 40 },
          { start: 40, end: 46.67 },
        ];
        const multiplier = 1;
        const lettersH2 = elem.querySelectorAll("h2 .letter");
        const lettersH4 = elem.querySelectorAll("h4 .letter");
        const svg = elem.querySelectorAll(".svg");
        const line = elem.querySelectorAll(".line .anime");
        let lines = [];
        let around = [];
        let svgLines = [];
        if (index === 1 || index === 3 || index === 5 || index === 7) {
          // eslint-disable-next-line no-unused-vars
          around = document.querySelectorAll(
            `svg${
              index === 1
                ? ".ship"
                : index === 3
                ? ".motor"
                : index === 5
                ? ".building"
                : ".train"
            } path.around`
          );
          // eslint-disable-next-line no-unused-vars
          lines = document.querySelectorAll(
            `svg${
              index === 1
                ? ".ship"
                : index === 3
                ? ".motor"
                : index === 5
                ? ".building"
                : ".train"
            } path.lines`
          );
          svgLines = document.querySelectorAll(
            `svg${
              index === 1
                ? ".ship"
                : index === 3
                ? ".motor"
                : index === 5
                ? ".building"
                : ".train"
            }`
          );
        }
        const card = elem.querySelectorAll(".card");
        const cardRow = elem.querySelectorAll(".card .row");
        const border = elem.querySelectorAll(".border");
        // eslint-disable-next-line no-unused-vars
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: elem,
            pin: elem,
            scrub: 1,
            end: () => `+=300%`,
            pinSpacer: false,
            normalize: true,
            invalidateOnRefresh: false,
          },
          onUpdate: () => {
            self.scroll();
            self.frame =
              startStop[index].start +
              tl.progress() * (startStop[index].end - startStop[index].start);
            if (index === 3 || index === 5) {
              self.animation.motor = Math.abs(
                (tl.scrollTrigger.getVelocity() / 500).toFixed(0) * 500
              );
            }
          },
        });
        if (svg.length > 0) {
          tl.from(elem, {
            opacity: 0,
            y: "100%",
            duration: multiplier,
          });
          tl.from(
            lettersH2,
            {
              opacity: 0,
              stagger: multiplier * 0.01,
              duration: multiplier * 0.1,
            },
            `<${multiplier * 0.2}`
          );
          if (lettersH4.length > 0) {
            tl.from(
              lettersH4,
              {
                opacity: 0,
                stagger: multiplier * 0.005,
                duration: multiplier * 0.03,
              },
              `<${multiplier * 0.15}`
            );
          }
          tl.from(
            svg,
            {
              opacity: 0,
              scaleX: 0,
              transformOrigin: "0 0",
              duration: multiplier * 0.7,
            },
            `<${multiplier * 0.1}`
          );
          tl.from(
            line,
            {
              opacity: 0,
              scaleX: 0,
              transformOrigin: "0 0",
              stagger: multiplier * 0.05,
              duration: multiplier * 0.07,
            },
            `<${multiplier * 0.7}`
          );
          tl.to(
            {},
            {
              duration: multiplier * 0.5,
            }
          );
          // tl.to(
          //   elem,
          //   {
          //     opacity: 0,
          //     duration: multiplier * 0.05,
          //     y: "-100%",
          //   },
          //   `+=${multiplier}`
          // );
        } else if (card.length > 0) {
          tl.to(
            // eslint-disable-next-line no-undef
            around,
            {
              strokeDashoffset: 0,
              duration: multiplier * 0.5,
            },
            `<${multiplier * 0.5}`
          );
          tl.to(
            // eslint-disable-next-line no-undef
            lines,
            {
              strokeDashoffset: 0,
              duration: multiplier * 0.5,
            },
            `<${multiplier * 0.5}`
          );
          tl.from(
            card,
            {
              y: "100vh",
              duration: multiplier * 0.5,
            },
            `<-${multiplier}`
          );
          tl.from(
            border,
            {
              height: "100%",
              duration: multiplier * 0.5,
            },
            `<${multiplier * 0.4}`
          );
          tl.to(
            border,
            {
              background:
                "linear-gradient(90deg, rgba(221,221,28,1) 0%, rgba(221,221,28,1) 94.28%, rgba(221,221,28,0) 94.28%, rgba(221,221,28,0) 95.17%, rgba(221,221,28,1) 95.17%, rgba(221,221,28,1) 96.96%, rgba(221,221,28,0) 96.96%, rgba(221,221,28,0) 97.85%, rgba(221,221,28,1) 97.85%, rgba(221,221,28,1) 98.75%, rgba(221,221,28,0) 98.75%, rgba(221,221,28,0) 99.64%, rgba(221,221,28,1) 99.64%, rgba(221,221,28,1) 100%)",
              duration: multiplier * 0.01,
            },
            `<${multiplier * 0.49}`
          );
          tl.from(
            card,
            {
              backgroundColor: "transparent",
              duration: multiplier * 0.5,
            },
            `<${multiplier * 0.4}`
          );
          tl.from(
            cardRow,
            {
              opacity: 0,
              duration: multiplier * 0.1,
              stagger: multiplier * 0.05,
            },
            `<${multiplier * 0.4}`
          );
          tl.to(
            self.animation,
            {
              weight: 504000,
              container: 36,
              duration: multiplier * 0.5,
            },
            `+=${multiplier}`
          );
          if (index !== 8) {
            tl.to(
              [elem, svgLines],
              {
                opacity: 0,
                duration: multiplier * 0.2,
              },
              `+=${multiplier * 0.5}`
            );
          } else {
            tl.to({}, { duration: multiplier });
          }
        }
      });

      // eslint-disable-next-line no-inner-declarations
      function goToForm(e) {
        e.preventDefault();
        gsap.to(window, {
          duration: 5,
          scrollTo: {
            y: ".last-row",
            offsetY: window.innerHeight * 2.5,
          },
        });
      }

      // eslint-disable-next-line no-inner-declarations
      function goTop(e) {
        e.preventDefault();
        gsap.to(window, {
          duration: 1,
          scrollTo: 0,
        });
      }

      document
        .getElementById("formLink")
        .addEventListener("click", goToForm, false);
      document
        .getElementById("modal1")
        .addEventListener("click", goToForm, false);
      document.getElementById("logo").addEventListener("click", goTop, false);
    }
  },
};
</script>

<style lang="stylus">
#loadingScreen
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: black;
  z-index: 9999;
  display flex
  flex-direction column
  align-items center
  justify-content center

  .loading-circle
    width 200px
    height 200px
    background-color transparent
    position relative
    margin-bottom: 40px

    svg
      position absolute
      top 0
      left 0
      width 200px
      height 200px
      transform rotate(90deg)

    #percent
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
      font-family Arial, sans-serif
      font-size 20px
      font-weight 400
      line-height 26px
      text-align center
      color white

  p
    font-family Arial, sans-serif
    font-size 20px
    font-style normal
    font-weight 400
    line-height 26px
    letter-spacing 0
    text-align center
    color white

    br
      display block
</style>
