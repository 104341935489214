<template>
  <div :class="['canvas-container', { notLoaded: !loaded }, { debug: debug }]">
    <canvas ref="canvas" id="canvas"></canvas>
  </div>
</template>

<script>
import * as BABYLON from "babylonjs";
// import { DefaultRenderingPipeline } from "babylonjs";
import "babylonjs-loaders";
import "babylonjs-materials";

export default {
  name: "babylon",
  props: {
    debug: {
      type: Boolean,
      default: true,
    },
    frame: {
      type: Number,
      default: 0,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Function,
    },
  },
  data() {
    return {
      oldFrame: 99,
      instr: {},
    };
  },
  methods: {
    checkIe() {
      const { userAgent } = window.navigator;
      if (userAgent.indexOf("MSIE ") > 0) return true;
      return userAgent.indexOf("Trident/") > 0;
    },
  },
  async mounted() {
    // eslint-disable-next-line no-unused-vars
    let optimizer = null;
    let gl = null;

    // eslint-disable-next-line no-unused-vars
    function optimizeScene(scene) {
      // make instances to reduce draw calls
      {
        const mesh0 = scene.getMeshByName("Circle.004_primitive0");
        const mesh1 = scene.getMeshByName("Circle.004_primitive1");
        const mesh2 = scene.getMeshByName("head.001_primitive0");
        const mesh3 = scene.getMeshByName("head.001_primitive1");
        const mesh4 = scene.getMeshByName("head.001_primitive2");
        const arrayOfWind = [];
        // eslint-disable-next-line no-unused-vars
        const arrayOfTrains = [];
        for (let j = 0; j < arrayOfWind.length; j += 1) {
          for (let i = 0; i < 2; i += 1) {
            let newInstance = null;
            if (i === 0) {
              newInstance = mesh0.createInstance(
                `${arrayOfWind[j]}_primitive${i}_instance`
              );
            } else {
              newInstance = mesh1.createInstance(
                `${arrayOfWind[j]}_primitive${i}_instance`
              );
            }
            newInstance.setParent(scene.getNodeByName(arrayOfWind[j]));
            newInstance.position.x = 0;
            newInstance.position.y = 0;
            newInstance.position.z = 0;
            newInstance.rotation.x = 0;
            newInstance.rotation.y = 0;
            newInstance.rotation.z = 0;
            newInstance.alwaysSelectAsActiveMesh = true;
          }
          if (j !== 0) {
            scene.getNodeByName(`${arrayOfWind[j]}_primitive0`).dispose();
            scene.getNodeByName(`${arrayOfWind[j]}_primitive1`).dispose();
          }
        }
        for (let j = 0; j < arrayOfTrains.length; j += 1) {
          for (let i = 0; i < 3; i += 1) {
            let newInstance = null;
            if (i === 0) {
              newInstance = mesh2.createInstance(
                `${arrayOfTrains[j]}_primitive${i}_instance`
              );
            } else if (i === 1) {
              newInstance = mesh3.createInstance(
                `${arrayOfTrains[j]}_primitive${i}_instance`
              );
            } else {
              newInstance = mesh4.createInstance(
                `${arrayOfTrains[j]}_primitive${i}_instance`
              );
            }
            newInstance.setParent(scene.getNodeByName(arrayOfTrains[j]));
            newInstance.position.x = 0;
            newInstance.position.y = 0;
            newInstance.position.z = 0;
            newInstance.rotation.x = Math.PI;
            newInstance.rotation.y = 0;
            newInstance.rotation.z = 0;
            newInstance.alwaysSelectAsActiveMesh = true;
          }
          if (j !== 0) {
            scene.getMeshByName(`${arrayOfTrains[j]}_primitive0`).dispose();
            scene.getMeshByName(`${arrayOfTrains[j]}_primitive1`).dispose();
            scene.getMeshByName(`${arrayOfTrains[j]}_primitive2`).dispose();
          }
        }
      }
      const arrOfStatic = [
        "__root__",
        "Circle.013_primitive0",
        "Circle.013_primitive0",
        "Circle.013_primitive1",
        "Circle.013_primitive2",
        "Circle.013_primitive3",
        "Circle.013_primitive4",
        "Circle.013_primitive5",
        "Circle.013_primitive6",
        "Circle.013_primitive7",
        "Circle.013_primitive8",
        "Circle.013_primitive9",
      ];
      // eslint-disable-next-line no-unused-vars
      const glowMaterials = [
        "Green",
        "Headlight",
        "Headlight_alfa_station",
        "Red",
        "Turret",
        "Ultramarin",
        "Water",
        "Blue-light",
      ];
      const excludeGlow = ["Ground", "Water"];
      scene.meshes.forEach((mesh) => {
        if (!mesh.name.includes("ins")) {
          // mesh.doNotSyncBoundingInfo = true;
          mesh.isPickable = false;
          if (arrOfStatic.includes(mesh.name)) {
            mesh.freezeWorldMatrix();
          }
          if (
            mesh.name === "Circle.020" ||
            mesh.name === "Plane_primitive1" ||
            mesh.name === "Plane_primitive2"
          ) {
            mesh.material = scene.getMaterialByName("Blank_mat_alfa_station");
          }
          if (mesh.name === "Circle.013_primitive8") {
            mesh.material = scene.getMaterialByName("Headlight");
          }
          if (mesh.name === "Circle.013_primitive7") {
            mesh.material = scene.getMaterialByName("Blue-light");
          }
          mesh.hasVertexAlpha = !!(
            mesh.name.startsWith("Plane") || mesh.name.startsWith("Circle.020")
          );
          if (mesh.name.startsWith("head")) {
            mesh.alwaysSelectAsActiveMesh = true;
          }
          if (mesh.name !== "__root__") {
            if (mesh.material && excludeGlow.includes(mesh.material.name)) {
              gl.addExcludedMesh(mesh);
            }
          }
        }
      });
      scene.freezeActiveMeshes();
      scene.skipPointerMovePicking = true;
      scene.blockMaterialDirtyMechanism = true;
      scene.cleanCachedTextureBuffer();
      scene.materials.forEach((a) => {
        if (
          a.name === "Blank_mat_alfa_station.001" ||
          a.name === "Headlight.001" ||
          a.name === "Blue-light.001" ||
          a.name === "Light-gray_alfa_station"
        ) {
          a.dispose();
        }
        a.freeze();
      });
      if (scene.getMaterialByUniqueID(96)) {
        scene.getMaterialByUniqueID(96).dispose();
      }
      // optimisation
      const options = new BABYLON.SceneOptimizerOptions(60, 500);
      options.addOptimization(new BABYLON.HardwareScalingOptimization(0, 4));
      BABYLON.SceneOptimizerOptions.HighDegradationAllowed();
      optimizer = new BABYLON.SceneOptimizer(scene, options);
    }

    const self = this;
    const { canvas } = this.$refs;
    let scene = null;
    let camera = null;
    // let defaultPipeline = null;
    const engineSettings = {
      preserveDrawingBuffer: true,
      stencil: true,
      alpha: true,
      doNotHandleTouchAction: true,
      doNotHandleContextLost: true,
      powerPreference: "high-performance",
    };
    BABYLON.StandardMaterial.ReflectionTextureEnabled = false;
    BABYLON.StandardMaterial.FresnelEnabled = false;
    BABYLON.StandardMaterial.SpecularTextureEnabled = false;
    const engine = new BABYLON.Engine(canvas, true, engineSettings, true);
    engine.setHardwareScalingLevel(1 / Math.min(window.devicePixelRatio, 2));
    engine.doNotHandleContextLost = true;
    engine.enableOfflineSupport = false;
    if (!this.debug) {
      const loadingScreenDiv = document.getElementById("loadingScreen");

      // eslint-disable-next-line no-inner-declarations
      function CustomLoadingScreen() {}

      CustomLoadingScreen.prototype.displayLoadingUI = function () {
        // alert(this.loadingUIText);
      };
      CustomLoadingScreen.prototype.hideLoadingUI = function () {
        if (loadingScreenDiv) {
          self.update(100);
          // loadingScreenDiv.remove();
        }
      };
      // replace the default loading screen
      engine.loadingScreen = new CustomLoadingScreen();
      // show the loading screen
      engine.displayLoadingUI();
    }
    // do not Draco, if IE
    if (self.checkIe()) BABYLON.DracoCompression.DefaultNumWorkers = 0;
    const createScene = async () => {
      BABYLON.DracoCompression.Configuration = {
        decoder: {
          wasmUrl:
            "https://cdn.jsdelivr.net/gh/google/draco/javascript/draco_decoder.js",
          wasmBinaryUrl:
            "https://cdn.jsdelivr.net/gh/google/draco/javascript/draco_decoder.wasm",
          fallbackUrl:
            "https://cdn.jsdelivr.net/gh/google/draco/javascript/draco_wasm_wrapper.js",
        },
      };
      // eslint-disable-next-line no-unused-vars
      scene = new BABYLON.Scene(engine, {
        useGeometryUniqueIdsMap: true,
        // useMaterialMeshMap: true,
      });
      gl = new BABYLON.GlowLayer("glow", scene, {
        mainTextureSamples: 2,
        blurKernelSize: 44,
      });
      gl.intensity = 1;
      await BABYLON.SceneLoader.AppendAsync(
        "/models/",
        `model3.glb`,
        scene,
        function (e) {
          const percent = (e.loaded / 2320924) * 94;
          self.update(percent);
        }
      );
      optimizeScene(scene);
      self.update(95);
      scene.pointerMovePredicate = () => false;
      scene.pointerDownPredicate = () => false;
      scene.pointerUpPredicate = () => false;
      scene.clearCachedVertexData();
      scene.shadowEnabled = false;
      scene.disablePhysicsEngine();
      scene.disablePrePassRenderer();
      scene.disableSubSurfaceForPrePass();
      scene.imageProcessingConfiguration.contrast = 1.5;
      scene.imageProcessingConfiguration.exposure = 0.9;
      scene.getLightByName("Sun").shadowEnabled = false;
      scene.getLightByName("Sun").intensity = 0.5;

      // scene.debugLayer.show();
      // Change Material Colors
      // eslint-disable-next-line no-unused-vars
      function changeColor(r, g, b, object) {
        scene.getMaterialByName(object).emissiveColor = new BABYLON.Color3(
          r / 255,
          g / 255,
          b / 255
        ).toLinearSpace();
      }

      // Scene overall color
      scene.environmentTexture = null;
      scene.clearColor = BABYLON.Color3.Black();
      // Camera
      camera = scene.getCameraByName("Camera");
      scene.animationGroups[17].targetedAnimations.splice(1, 1);
      self.update(96);
      scene.animationGroups[0].stop();
      camera.position = scene.getNodeByName("Camera").position;
      camera.parent = scene.getNodeByName("__root__");
      scene.activeCamera = camera;
      scene.animationGroups[17].targetedAnimations[0].target =
        scene.activeCamera;
      scene.animationGroups[17].targetedAnimations[1].target =
        scene.activeCamera;
      camera.setTarget(scene.getNodeByName("Empty").position);
      scene.getNodeByName("Camera").dispose();
      camera.maxZ = 80;
      // set camera fov
      const ratio = window.innerWidth / window.innerHeight;
      if (ratio < 0.69 && camera.fov !== 0.75) {
        camera.fov = 0.75;
      } else if (ratio >= 1 && camera.fov > 0.4) {
        camera.fov = 0.388;
      } else if (ratio < 1 && ratio >= 0.69 && camera.fov !== 0.5) {
        camera.fov = 0.5;
      }
      // Camera update rotation
      let i = {
        x: 0,
        y: 0,
        z: 0,
      };
      scene.onAfterAnimationsObservable.add(function () {
        if (
          scene.getNodeByName("Empty").position.x !== i.x ||
          scene.getNodeByName("Empty").position.y !== i.y ||
          scene.getNodeByName("Empty").position.z !== i.z
        ) {
          camera.setTarget(scene.getNodeByName("Empty").position);
          i = {
            x: scene.getNodeByName("Empty").position.x,
            y: scene.getNodeByName("Empty").position.y,
            z: scene.getNodeByName("Empty").position.z,
          };
        }
      });
      // // colors
      changeColor(13, 54, 61, "Water");
      self.update(97);
      return scene;
    };
    scene = await createScene();

    // eslint-disable-next-line no-unused-vars
    function moveToFrame(frame) {
      scene.animationGroups.forEach((a) => {
        a.play();
        a.goToFrame(frame * 60);
        a.pause();
      });
      if (self.frame > 13.98 && self.frame < 15.11) {
        scene.getMeshByName("Plane_primitive0").visibility = 15 - self.frame;
        scene.getMeshByName("Plane_primitive1").visibility = 15 - self.frame;
        scene.getMeshByName("Plane_primitive2").visibility = 15 - self.frame;
        scene.getMeshByName("Circle.020").visibility = 15 - self.frame;
      } else if (15 - self.frame <= 0) {
        scene.getMeshByName("Plane_primitive0").visibility = 0;
        scene.getMeshByName("Plane_primitive1").visibility = 0;
        scene.getMeshByName("Plane_primitive2").visibility = 0;
        scene.getMeshByName("Circle.020").visibility = 0;
      } else {
        scene.getMeshByName("Plane_primitive0").visibility = 1;
        scene.getMeshByName("Plane_primitive1").visibility = 1;
        scene.getMeshByName("Plane_primitive2").visibility = 1;
        scene.getMeshByName("Circle.020").visibility = 1;
      }
    }

    engine.runRenderLoop(() => {
      if (scene) {
        scene.render();
        if (self.oldFrame !== self.frame) {
          self.oldFrame = self.frame;
          moveToFrame(self.frame);
        }
      }
    });
    self.update(98);
    let oldFinalWidth = -1;
    let oldHeight = -1;
    const root = document.documentElement;
    window.addEventListener("resize", () => {
      let finalWidth =
        window.innerWidth > (window.innerHeight / 1080) * 1920
          ? (window.innerHeight / 1080) * 1920
          : window.innerWidth;
      if (oldFinalWidth !== finalWidth) {
        document.documentElement.style.setProperty(
          "--vw",
          finalWidth / 100 + "px"
        );
        oldFinalWidth = finalWidth;
      }
      if (
        oldHeight > window.screen.height * 1.25 ||
        oldHeight < window.screen.height / 1.25
      ) {
        oldHeight = window.screen.height;
        root.style.setProperty("--height", window.screen.height + "px");
      }
      engine.resize();
      const ratio = window.innerWidth / window.innerHeight;
      if (ratio < 0.69 && camera.fov !== 0.75) {
        camera.fov = 0.75;
      } else if (ratio >= 1 && camera.fov > 0.4) {
        camera.fov = 0.388;
      } else if (ratio < 1 && ratio >= 0.69 && camera.fov !== 0.5) {
        camera.fov = 0.5;
      }
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.canvas-container
  width 100vw
  height 100vh
  position fixed
  top 0
  left 0
  @media screen and (min-aspect-ratio: 69 / 100) and (max-aspect-ratio: 1 / 1) and (-webkit-min-device-pixel-ratio: 2) {
    height var(--height)
  }
  @media screen and (max-aspect-ratio: 69 / 100) and (-webkit-min-device-pixel-ratio: 2), (max-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
    height var(--height)
  }
  @media (max-height: 500px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    height var(--height)
  }

canvas
  width 100%
  height 100%
  touch-action none
</style>
<style lang="stylus">
.canvas-container.notLoaded
  transform translateY(0) !important
</style>
