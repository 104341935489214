<template>
  <canvas id="background"></canvas>
  <div class="logo">
    <img
      src="@/assets/img/archilogo-big.svg"
      alt="Archimatika logo"
      title="Archimatika logo"
    />
  </div>
  <router-link to="/" class="comeback" data-name="go to main page"
    >go to main page
  </router-link>
</template>
<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default {
  data() {
    return {
      stop: false,
      clientX: 0,
      clientY: 0,
    };
  },
  mounted() {
    const self = this;

    function resizeCanvas() {
      if (renderer) {
        let aspect = window.innerWidth / window.innerHeight;
        renderer.setSize(
          window.innerWidth /
            (window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio),
          window.innerHeight /
            (window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio),
          false
        );
        if (camera) {
          camera.aspect = aspect;
          camera.updateProjectionMatrix();
          if (window.innerWidth < 900 && window.innerWidth >= 500) {
            camera.fov = 50;
          } else if (window.innerWidth < 500) {
            camera.fov = 70;
          } else {
            camera.fov = 25;
          }
        }
      }
    }

    window.addEventListener(
      "resize",
      function () {
        resizeCanvas();
      },
      false
    );
    window.addEventListener(
      "mousemove",
      function (e) {
        self.mouseMoved(e);
      },
      false
    );
    window.addEventListener(
      "orientationchange",
      function () {
        resizeCanvas();
      },
      false
    );
    const canvas = document.querySelector("canvas#background");
    const scene = new THREE.Scene();
    let camera = new THREE.PerspectiveCamera(25, 1.777777, 0.11, 1000);
    const loader = new GLTFLoader();
    const url = "/models/ARCH-404.glb";
    scene.add(camera);
    let mixers = [];
    loader.load(url, (gltf) => {
      scene.add(gltf.scene);
      camera = gltf.cameras[0];
      if (window.innerWidth < 900 && window.innerWidth >= 500) {
        camera.fov = 50;
      } else if (window.innerWidth < 500) {
        camera.fov = 70;
      } else {
        camera.fov = 25;
      }
      for (let i = 0; i < gltf.animations.length; i++) {
        let mixer = new THREE.AnimationMixer(scene.children[1]);
        let action = mixer.clipAction(gltf.animations[i]);
        action.play();
        mixers.push(mixer);
      }
      scene.children[1].children[0].children[0].intensity = 3;
      scene.children[1].children[0].children[0].distance = 20;
      scene.children[1].children[1].children[0].intensity = 3;
      scene.children[1].children[1].children[0].distance = 20;
      scene.children[1].children[3].children[0].intensity = 3;
      scene.children[1].children[3].children[0].distance = 20;

      resizeCanvas();
    });
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: false,
    });
    renderer.setPixelRatio(
      window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio
    );
    resizeCanvas();
    const clock = new THREE.Clock();
    const tick = () => {
      let delta = clock.getDelta();

      if (mixers.length > 0 && self.stop === false) {
        for (let i = 0; i < mixers.length; i++) {
          if (mixers[i].time < 6.5) {
            mixers[i].update(delta * 1);
          } else {
            self.stop = true;
            for (let i = 0; i < mixers.length; i++) {
              mixers[i].setTime(7);
            }
          }
        }
      }
      if (scene.children[1] && self.clientX !== 0) {
        scene.children[1].children[3].children[0].position.x =
          (self.clientX - 0.75) * 20;
        scene.children[1].children[3].children[0].position.y =
          (self.clientY - 0.5) * -10;
      }
      if (camera) {
        renderer.render(scene, camera);
      }

      window.requestAnimationFrame(tick);
    };
    tick();
  },
  methods: {
    mouseMoved(e) {
      if (window.innerWidth > 1024) {
        this.clientX = e.clientX / window.innerWidth;
        this.clientY = e.clientY / window.innerHeight;
      }
    },
  },
};
</script>
<style scoped lang="stylus">
.comeback
  position: fixed
  bottom: 70px
  left: 50%
  transform: translateX(-50%)
  border: 2px solid #efe5f9
  text-transform: uppercase
  font-family: Stolzl Display, sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 16px
  letter-spacing: 0.3em
  text-align: center
  color: transparent
  padding: 20px 36px
  text-decoration: none
  margin: 0
  outline: 0
  overflow: hidden
  box-sizing unset

  &:before
    content: attr(data-name)
    position: absolute
    top: 0
    left: 0
    padding: 20px 36px
    transform: translateX(0)
    transition: 0.3s transform ease-in-out
    text-transform: uppercase
    font-family: Stolzl Display, sans-serif
    font-size: 16px
    font-style: normal
    font-weight: 400
    line-height: 16px
    letter-spacing: 0.3em
    text-align: center
    color: #efe5f9
    @media (max-width: 600px)
      width: 225px

  &:after
    content: attr(data-name)
    position: absolute
    top: 0
    left: 0
    padding: 20px 36px
    text-transform: uppercase
    font-family: Stolzl Display, sans-serif
    font-size: 16px
    font-style: normal
    font-weight: 400
    line-height: 16px
    letter-spacing: 0.3em
    text-align: center
    color: #efe5f9
    background: url('../assets/img/button-hover.jpg')
    background-size: cover
    transform: scaleX(0)
    transition: 0.3s transform ease-in-out
    transform-origin: right
    @media (max-width: 600px)
      width: 225px

  &:hover:after
    @media (min-width 1280px)
      transform: scaleX(1)

  &:hover:before
    @media (min-width 1280px)
      transform: translateX(-100%)

  @media (max-width: 600px)
    width: 225px
  @media (max-width: 900px) and (orientation: landscape)
    bottom: 50px


.logo
  position: fixed
  top: 110px
  left: 50%
  transform: translateX(-50%)
  width: 464px
  max-width: calc(100% - 2 * var(--lr))
  @media (max-width: 600px)
    top: 100px
    width: 255px
  @media (max-width: 900px) and (orientation: landscape)
    top: 30px
    width: 350px

  img
    width: 100%
</style>
