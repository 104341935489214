<template>
  <div class="group">
    <input
      type="text"
      @input="$emit('update:modelValue', $event.target.value)"
      :value="modelValue"
      required
      v-if="!textarea"
      :name="inputPlaceHolder"
      :id="inputPlaceHolder"
    />
    <textarea
      v-else
      :name="inputPlaceHolder"
      @input="$emit('update:modelValue', $event.target.value)"
      :value="modelValue"
      :rows="5"
      required
      :id="inputPlaceHolder"
    />
    <svg
      width="415"
      height="2"
      viewBox="0 0 415 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :class="{ error2: error }"
    >
      <rect width="375" height="2" fill="#FFFEFF" />
      <rect x="381" width="12" height="2" fill="#FFFEFF" />
      <rect x="399.777" width="6.25887" height="2" fill="#FFFEFF" />
      <rect x="412" width="3" height="2" fill="#FFFEFF" />
    </svg>

    <label :for="inputPlaceHolder"
      >{{ inputPlaceHolder }}
      <span v-if="!required && textarea === true"
        >({{ lang === 0 ? "optional" : "необязательно" }})</span
      ></label
    >
    <p v-if="type === 'tel'">
      {{
        lang === 0
          ? "Enter the phone number in the international format"
          : "Введите телефон в международном формате"
      }}
    </p>
    <div v-if="error" class="error">
      {{
        type === "name"
          ? lang === 0
            ? "Name is required"
            : "Имя обязательно"
          : type === "email" && modelValue !== ""
          ? lang === 0
            ? "Email is incorrect"
            : "Почта введена неверно"
          : lang === 0
          ? "Email is required"
          : "Почта обязательна"
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: "InputItem",
  props: [
    "inputPlaceHolder",
    "modelValue",
    "grid",
    "textarea",
    "error",
    "type",
    "required",
    "width",
    "lang",
  ],
};
</script>
<style scoped>
.group {
  position: relative;
  margin-bottom: calc(var(--vw) * 2.55);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.group:last-child {
  margin-bottom: 0;
}

input,
textarea {
  font-family: Arial, sans-serif;
  font-size: calc(var(--vw) * 1.33);
  font-style: normal;
  font-weight: 400;
  display: block;
  width: 100%;
  border: none;
  background-color: transparent;
  color: white;
  padding: calc(var(--vw) * 0.52) calc(var(--vw) * 0.52) calc(var(--vw) * 0.36)
    calc(var(--vw) * 1.04);
  margin: 0;
  transition: 0.4s cubic-bezier(0.79, 0, 0.21, 0.99) all;
  -moz-transition: 0.4s cubic-bezier(0.79, 0, 0.21, 0.99) all;
  -webkit-transition: 0.4s cubic-bezier(0.79, 0, 0.21, 0.99) all;
  resize: none;
  line-height: calc(var(--vw) * 1.67);
  position: relative;
  -webkit-appearance: none;
  border-radius: 0;
}

input:hover,
textarea:hover {
  background: #efe5f91a;
}

textarea {
  line-height: 1.15;
  width: 100%;
  height: calc(var(--vw) * 12.96);
}

input:focus,
textarea:focus {
  outline: none;
}

.error {
  color: #ee5c67;
  padding-left: 0;
  position: absolute;
  bottom: calc(var(--vw) * -1.04);
  font-family: Arial, sans-serif;
  font-size: calc(var(--vw) * 0.63);
  font-style: normal;
  font-weight: 400;
  line-height: calc(var(--vw) * 0.72);
  text-align: left;
}

/* LABEL ======================================= */
label {
  font-family: Arial, sans-serif;
  font-size: calc(var(--vw) * 1.33);
  font-style: normal;
  font-weight: 400;
  color: white;
  position: absolute;
  pointer-events: none;
  line-height: calc(var(--vw) * 1.67);
  left: calc(var(--vw) * 1.56);
  top: calc(var(--vw) * 0.52);
  word-break: keep-all;
  white-space: nowrap;
  transition: 0.4s cubic-bezier(0.79, 0, 0.21, 0.99) all;
  -moz-transition: 0.4s cubic-bezier(0.79, 0, 0.21, 0.99) all;
  -webkit-transition: 0.4s cubic-bezier(0.79, 0, 0.21, 0.99) all;
}

label span {
  font-size: 0.9em;
  color: white;
}

input:focus ~ label,
input:valid ~ label {
  top: calc(var(--vw) * -1.56);
  font-size: 1rem;
}

textarea:focus ~ label,
textarea:valid ~ label {
  top: calc(var(--vw) * -1.56);
  font-size: 1rem;
}
p {
  font-family: Arial, sans-serif;
  font-size: calc(var(--vw) * 0.63);
  font-style: normal;
  font-weight: 400;
  line-height: 1.15;
  text-align: left;
  color: white;
  margin-top: calc(var(--vw) * 0.68);
}
svg {
  width: 100%;
  height: auto;
}
svg.error2 rect {
  fill: #ee5c67;
}
</style>
