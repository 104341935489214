<template>
  <router-view />
</template>
<style lang="stylus">
:root
  --height: 100vh

*
  box-sizing border-box
  margin: 0
  padding 0

::-webkit-scrollbar
  width 0

html,
body
  width 100%
  height 100%
  margin 0
  padding 0
  position relative
  overflow-x hidden

  &.overflow
    overflow hidden

body
  height fit-content

#app
  width 100%
  height 100%
  overflow hidden
</style>
